<template>
  <v-container v-if="!allLoaded" id="users-table" fluid tag="section">
    <v-form
      @submit.stop.prevent="getUsersByPage()"
      ref="form"
      v-model="valid"
      :lazy-validation="lazy"
      data-test="Admin:ManagerTable:FormSearch"
    >
      <v-row v-if="filter" justify="center" class="mt-6">
        <v-text-field
          class="mx-3"
          dense
          outlined
          :label="$t('search')"
          v-model="nameFilter"
          :style="isMobile() ? 'max-width: 25%' : 'max-width: 35%'"
          data-test="Admin:ManagerTable:TxtSearchBar"
        ></v-text-field>
        <v-select
          class="mx-3"
          style="max-width: 30%"
          outlined
          dense
          :label="$t('sort_by')"
          :items="filterOrderOptions"
          v-model="filterOrderSelected"
          item-text="text"
          item-value="index"
        >
        </v-select>
        <v-btn class="mx-3 secondary" type="submit">{{ $t("apply") }}</v-btn>
      </v-row>
    </v-form>
    <v-simple-table>
      <thead>
        <tr>
          <th class="text-center">
            {{ $t("name") }}
            <v-icon
              small
              :color="orderBy == -1 ? 'primary' : ''"
              class="ml-2"
              @click="headerClick(-1)"
              >{{ orderByMode == 0 ? "mdi-arrow-up" : "mdi-arrow-down" }}
            </v-icon>
          </th>
          <th class="text-center">{{ $t("email") }}</th>
          <th class="text-center">{{ $t("manager_company") }}</th>
          <th class="text-center">{{ $t("options") }}</th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="(user, index) in users" :key="index">
          <td class="text-center">
            <div class="div-user-list-name">{{ user.fullName }}</div>
            <div v-if="user.deleted" class="div-deleted">
              {{ $t("deleted_user") }}
            </div>
          </td>
          <td class="text-center">{{ user.email }}</td>
          <td class="text-center">{{ user.managerPartnerName }}</td>
          <td class="text-center">
            <v-btn
              class="px-2 ml-1 secondary"
              @click="editUser(user.id)"
              min-width="0"
              small
            >
              <v-icon small>mdi-pencil</v-icon>
            </v-btn>
            <v-btn
              class="px-2 ml-1"
              @click="openDeleteDialog(user)"
              color="red"
              min-width="0"
              small
              :disabled="user.deleted"
            >
              <v-icon small>mdi-trash-can</v-icon>
            </v-btn>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <v-pagination
      color="secondary"
      @input="changePage"
      :length="totalPages"
      v-model="page"
    >
    </v-pagination>
    <v-dialog v-if="deleteDialog" v-model="deleteDialog">
      <DeleteConfirmationModal
        :obj="deleteObj"
        :name="deleteObj.fullName"
        @close="deleteDialog = false"
        @delete="deleteUser"
        :useLocalLoading="true"
        :localLoading="deleteUserLoading"
      ></DeleteConfirmationModal>
    </v-dialog>
  </v-container>
  <v-container v-else>
    <v-progress-circular
      style="margin-left: 50%"
      indeterminate
      size="70"
      color="primary"
    ></v-progress-circular>
  </v-container>
</template>

<script>
import ApiService from "@/services/ApiService";
import DeleteConfirmationModal from "@/views/pages/DeleteConfirmationModal";
import { TableEnum } from "@/shared/enums/Table";
import { formatCurrency } from "@/shared/helpers/currencyHelper";

export default {
  name: "ManagerUserTable",

  components: {
    DeleteConfirmationModal,
  },

  data: () => ({
    apiService: new ApiService(),
    allLoaded: false,
    filter: true,
    page: 1,
    totalPages: 1,
    nameFilter: "",
    valid: true,
    lazy: false,
    filterOrderSelected: -1,
    deleteObj: null,
    deleteDialog: false,
    userId: null,
    users: [],
    deleteUserLoading: false,
    orderBy: null,
    orderByMode: 0,
    TableEnum: TableEnum,
    tab: null,
    formatCurrency: formatCurrency,
  }),
  async created() {
    await this.getUsersByPage();
  },
  computed: {
    filterOrderOptions() {
      return [
        {
          index: -1,
          text: this.$t("name"),
        },
        {
          index: 0,
          text: this.$t("manager_company"),
        },
      ];
    },
  },
  methods: {
    isMobile() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return true;
      }
      return false;
    },
    headerClick(col) {
      this.orderBy = col;
      if (this.orderByMode == 0) {
        this.orderByMode = 1;
      } else {
        this.orderByMode = 0;
      }
      this.getUsersByPage();
    },
    async getUsersByPage() {
      this.allLoaded = true;

      let page = this.page;

      if (this.$route.query.page) {
        page = parseInt(this.$route.query.page);
      }

      let params = {
        page: page,
        order: this.filterOrderSelected,
      };

      if (this.nameFilter) {
        params.nameFilter = this.nameFilter;
      }

      if (this.orderBy != null) {
        params.order = this.orderBy;
        params.orderMode = this.orderByMode;
      }

      await this.apiService
        .postRequest("manageruser/list/filter", params)
        .then((resp) => {
          const totalItemsPerPage = resp.content.perPage;

          this.page = resp.content.currentPage;
          this.totalPages = Math.ceil(resp.content.total / totalItemsPerPage);
          this.users = resp.content.items;
        })
        .catch((error) => {
          this.$toast.error(this.$t("an_error_occoured"));
        });

      this.allLoaded = false;
    },
    async changePage() {
      this.$router.push({
        path: "/users",
        query: { page: this.page, tab: TableEnum.MANAGER_USER },
      });

      await this.getUsersByPage();
    },
    openDeleteDialog(user) {
      this.deleteDialog = true;
      this.deleteObj = user;
    },
    async editUser(userId) {
      await this.$router.push({
        path: `/users/edit/${userId}`,
        params: { user_id: userId },
      });
    },
    async deleteUser(user) {
      this.deleteUserLoading = true;

      await this.apiService
        .deleteRequest(`user/delete/`, `"${user.id}"`)
        .then(async (resp) => {
          this.deleteDialog = false;
          this.$toast.success(this.$t("saved"));
          await this.getUsersByPage();
        })
        .catch((error) => {
          this.$toast.error(this.$t("an_error_occoured"));
        });

      this.deleteUserLoading = false;
    },
  },
};
</script>

<style scoped>
.table-gap > span {
  display: block;
}

.table-gap > span + span {
  margin-top: 4px;
}
</style>
