<template>
  <v-container v-if="all_loaded" id="users-table" fluid tag="section">
    <v-btn
      color="secondary"
      style="margin-right: 80px"
      rounded
      @click="download_investors_sheet"
      absolute
      :loading="investorsSheetDownloading"
      fab
      top
      right
      small
    >
      <v-icon>mdi-download</v-icon>
    </v-btn>
    <v-form
      @submit.stop.prevent="get_users_by_page()"
      ref="form"
      v-model="valid"
      :lazy-validation="lazy"
      data-test="Admin:InvestorTable:FormSearch"
    >
      <v-row v-if="filter" justify="center" class="mt-6">
        <v-text-field
          class="mx-3"
          dense
          outlined
          :label="$t('search')"
          v-model="name_filter"
          :style="isMobile() ? 'max-width: 25%' : 'max-width: 35%'"
          data-test="Admin:InvestorTable:TxtSearchBar"
        ></v-text-field>
        <v-select
          class="mx-3"
          style="max-width: 30%"
          outlined
          dense
          :label="'Ordenar Por'"
          :items="filter_order_options"
          v-model="filter_order_selected"
          item-text="text"
          item-value="index"
        >
        </v-select>
        <v-btn class="mx-3 secondary" type="submit">{{ $t("apply") }}</v-btn>
      </v-row>
    </v-form>
    <v-simple-table>
      <thead>
        <tr>
          <th class="text-center">
            {{ $t("name") }}
            <v-icon
              small
              :color="orderBy == -1 ? 'primary' : ''"
              class="ml-2"
              @click="header_click(-1)"
              >{{ orderByMode == 0 ? "mdi-arrow-up" : "mdi-arrow-down" }}
            </v-icon>
          </th>
          <th class="text-center">
            {{ $t("email") }}
            <v-icon
              small
              :color="orderBy == 0 ? 'primary' : ''"
              class="ml-2"
              @click="header_click(0)"
              >{{ orderByMode == 0 ? "mdi-arrow-up" : "mdi-arrow-down" }}
            </v-icon>
          </th>
          <th class="text-center">
            {{ $tc("partner", 1) }}
            <v-icon
              small
              :color="orderBy == 1 ? 'primary' : ''"
              class="ml-2"
              @click="header_click(1)"
              >{{ orderByMode == 0 ? "mdi-arrow-up" : "mdi-arrow-down" }}
            </v-icon>
          </th>
          <th class="text-center">{{ $tc("banker", 1) }}</th>
          <th class="text-center">
            {{ $t("register_date") }}
            <v-icon
              small
              :color="orderBy == 2 ? 'primary' : ''"
              class="ml-2"
              @click="header_click(2)"
              >{{ orderByMode == 0 ? "mdi-arrow-up" : "mdi-arrow-down" }}
            </v-icon>
          </th>
          <th class="text-center">
            {{ $t("last_login") }}
            <v-icon
              small
              :color="orderBy == 3 ? 'primary' : ''"
              class="ml-2"
              @click="header_click(3)"
              >{{ orderByMode == 0 ? "mdi-arrow-up" : "mdi-arrow-down" }}
            </v-icon>
          </th>
          <th class="text-center">{{ $t("download_allocations_report") }}</th>
          <th class="text-center">{{ $t("options") }}</th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="(user, index) in users" :key="index">
          <td class="text-center">
            <div>
              <v-icon color="secondary" v-if="user.IsAlpha">mdi-alpha</v-icon
              >{{ gs.show_name(user) }}
            </div>
            <div v-if="user.Deleted" class="div-deleted">
              {{ $t("deleted_user") }}
            </div>
          </td>
          <td
            class="text-center"
            :data-test="`Admin:InvestorTable:TdEmail:${user.Email}`"
          >
            {{ user.Email }}
          </td>
          <td class="text-center">
            {{
              user.PartnerB2b && user.PartnerB2b.Name
                ? user.PartnerB2b.Name
                : "-"
            }}
          </td>
          <td class="text-center">{{ gs.show_name(user.Banker) }}</td>
          <td class="text-center">
            {{ user.RegisterDate ? format_data(user.RegisterDate) : "-" }}
          </td>
          <td class="text-center">
            {{ user.LastLoginDate ? format_data(user.LastLoginDate) : "-" }}
          </td>
          <td class="text-center">
            <v-btn
              class="px-2 ml-1 secondary"
              @click="download_allocations_report(user)"
              min-width="0"
              small
              :loading="user.ReportDownloading"
            >
              <v-icon>mdi-download</v-icon>
            </v-btn>
          </td>
          <td class="text-center">
            <v-btn
              v-if="user.Type == UserTypeEnum.Investor"
              class="px-2 ml-1 secondary"
              @click="open_user_pdf(user)"
              min-width="0"
              small
              :loading="userDownloading == user.Id"
            >
              <v-icon>mdi-file-account</v-icon>
            </v-btn>
            <v-btn
              class="px-2 ml-1 secondary"
              @click="edit_user(user.Id)"
              min-width="0"
              small
            >
              <v-icon small>mdi-pencil</v-icon>
            </v-btn>
            <v-btn
              class="px-2 ml-1"
              @click="open_delete_dialog(user)"
              color="red"
              min-width="0"
              small
              :disabled="user.Deleted"
            >
              <v-icon small>mdi-trash-can</v-icon>
            </v-btn>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <v-pagination
      color="secondary"
      @input="changePage"
      :length="total_pages"
      v-model="page"
    >
    </v-pagination>
    <v-dialog v-if="delete_dialog" v-model="delete_dialog">
      <DeleteConfirmationModal
        :obj="delete_obj"
        :name="delete_obj.Name + ' ' + delete_obj.LastName"
        @close="delete_dialog = false"
        @delete="delete_user"
      ></DeleteConfirmationModal>
    </v-dialog>
  </v-container>
  <v-container v-else>
    <v-progress-circular
      style="margin-left: 50%"
      indeterminate
      size="70"
      color="primary"
    ></v-progress-circular>
  </v-container>
</template>

<script>
import ApiService from "@/services/ApiService";
import GeneralServices from "@/services/GeneralServices";
import DeleteConfirmationModal from "@/views/pages/DeleteConfirmationModal";
import moment from "moment";
import { TableEnum } from "@/shared/enums/Table";
import { UserTypeEnum } from "@/shared/enums/UserType";

export default {
  name: "InvestorTable",

  components: {
    DeleteConfirmationModal,
  },
  props: {
    UserFullName: String,
  },
  data: () => ({
    apiService: new ApiService(),
    gs: new GeneralServices(),
    all_loaded: false,
    filter: true,
    page: 1,
    total_pages: 1,
    name_filter: "",
    valid: true,
    lazy: false,
    filter_order_selected: -1,
    delete_obj: null,
    delete_dialog: false,
    userId: null,
    userDownloading: false,
    investorsSheetDownloading: false,
    users: [],
    orderBy: null,
    orderByMode: 0,
    TableEnum: TableEnum,
    tab: null,
    UserTypeEnum,
  }),
  async created() {
    if (this.UserFullName != null) {
      this.name_filter = this.UserFullName;
    }
    this.get_users_by_page();
  },
  computed: {
    filter_order_options() {
      return [
        {
          index: -1,
          text: this.$t("name"),
        },
        {
          index: 0,
          text: this.$t("email"),
        },
        {
          index: 1,
          text: this.$t("partner_name"),
        },
        {
          index: 2,
          text: this.$t("register_date"),
        },
        {
          index: 3,
          text: this.$t("last_login"),
        },
      ];
    },
  },
  methods: {
    download_investors_sheet() {
      this.investorsSheetDownloading = true;
      this.apiService
        .getRequest(`investor/generate-investors-sheet`)
        .then((resp) => {
          resp = "data:application/xlsx;base64," + resp;
          const link = document.createElement("a");
          link.href = resp;
          link.setAttribute("download", `Investidores.xlsx`);
          document.body.appendChild(link);
          link.click();
          this.investorsSheetDownloading = false;
        })
        .catch((error) => {
          this.investorsSheetDownloading = false;
        });
    },
    isMobile() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return true;
      }
      return false;
    },
    download_allocations_report(i) {
      i.ReportDownloading = true;
      this.apiService
        .getRequest(`investor/generate-investor-allocations-report/${i.Id}`)
        .then((resp) => {
          resp = "data:application/xlsx;base64," + resp;
          const link = document.createElement("a");
          link.href = resp;
          link.setAttribute(
            "download",
            `${i.Name} ${i.LastName} - ${moment().format("DD-MM-YYYY")}.xlsx`
          );
          document.body.appendChild(link);
          link.click();
          i.ReportDownloading = false;
        })
        .catch((error) => {
          i.ReportDownloading = false;
        });
    },
    open_user_pdf(user) {
      this.userDownloading = user.Id;
      this.apiService
        .getRequest("investor/generate/pdf/" + user.Id)
        .then((resp) => {
          resp = "data:application/pdf;base64," + resp;
          const link = document.createElement("a");
          link.href = resp;
          link.setAttribute("download", user.Name + " " + user.LastName);
          document.body.appendChild(link);
          link.click();
          this.userDownloading = null;
        })
        .catch((error) => {});
    },
    header_click(col) {
      this.orderBy = col;
      if (this.orderByMode == 0) {
        this.orderByMode = 1;
      } else {
        this.orderByMode = 0;
      }
      this.get_users_by_page();
    },
    get_users_by_page() {
      var page = this.page;
      if (this.$route.query.page) {
        page = parseInt(this.$route.query.page);
      }
      var params = {
        Page: page,
        Order: this.filter_order_selected,
      };
      if (this.name_filter) {
        params.NameFilter = this.name_filter;
      }
      if (this.orderBy != null) {
        params.Order = this.orderBy;
        params.OrderMode = this.orderByMode;
      }
      this.apiService
        .postRequest("investor/list/filter", params)
        .then((resp) => {
          var json = JSON.parse(resp);
          this.page = json.Pager.CurrentPage;
          this.total_pages = json.Pager.TotalPages;
          this.users = json.Users;
          this.all_loaded = true;
        })
        .catch((error) => {
          this.all_loaded = true;
        });
    },
    changePage() {
      this.$router.push({
        path: "/users",
        query: { page: this.page, tab: TableEnum.INVESTOR },
      });
      this.get_users_by_page();
    },
    open_delete_dialog: function (user) {
      this.delete_dialog = true;
      this.delete_obj = user;
    },
    check_user_type: function (user_type) {
      if (user_type == 0) {
        return this.$tc("investor", 1);
      } else if (user_type == 1) {
        return this.$tc("admin", 1);
      } else {
        return this.$tc("partner", 1);
      }
    },
    format_data: function (value) {
      if (value == "0001-01-01T00:00:00") return "-";
      return moment(value).format("HH:mm DD/MM/YYYY ");
    },
    edit_user: function (user_id) {
      this.$router.push({
        path: `/users/edit/${user_id}`,
        params: { user_id: user_id },
      });
    },
    delete_user: function (user) {
      this.all_loaded = false;
      this.apiService
        .deleteRequest("user/delete", `"${user.Id}"`)
        .then((resp) => {
          var index = this.users.indexOf(user);
          this.users[index].Deleted = true;
          this.delete_dialog = false;
          this.all_loaded = true;
        })
        .catch((error) => {
          this.all_loaded = true;
        });
    },
  },
};
</script>
